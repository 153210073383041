import axios, { AxiosInstance } from 'axios';
import { PlaceResult, LatLngLiteral } from '@/types';

interface OutscraperPlaceResult {
  name: string;
  place_id: string;
  google_id: string;
  full_address: string;
  address: string;
  phone?: string;
  site?: string;
  latitude: number;
  longitude: number;
  rating?: number;
  reviews?: number;
  category?: string[];
  business_status?: string;
  type?: string;
  subtypes?: string[];
  location_link?: string;
}

interface OutscraperResponse {
  data: OutscraperPlaceResult[] | OutscraperPlaceResult[][];
  status: 'Success' | 'Error';
  error?: string;
}

export class OutscraperService {
  private api: AxiosInstance;
  private readonly MAX_RETRIES = 3;
  private readonly BASE_DELAY = 2000;
  private readonly BATCH_SIZE = 10;

  constructor(accessToken : string) {
    this.api = axios.create({
      baseURL: import.meta.env.VITE_OUTSCRAPER_API,
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
  }

  private async searchPlacesWithRetry(
    queries: string[],
    location: LatLngLiteral,
    radius: number,
    retryCount = 0
  ): Promise<OutscraperPlaceResult[]> {
    try {
      const response = await this.api.post<OutscraperResponse>('/', {
        query: queries,
        radius: Math.floor(radius * 1609.34), // Convert miles to meters
        coordinates: `${location.lat},${location.lng}`
      });

      if (response.data.status === 'Error') {
        throw new Error(response.data.error || 'API request failed');
      }

      let results: OutscraperPlaceResult[] = [];
      if (Array.isArray(response.data.data)) {
        if (response.data.data.length > 0) {
          if (Array.isArray(response.data.data[0])) {
            // Nested array format - flatten all results
            results = (response.data.data as OutscraperPlaceResult[][]).flat();
          } else {
            // Single array format
            results = response.data.data as OutscraperPlaceResult[];
          }
        }
      }

      return results.filter(result =>
        result &&
        result.place_id &&
        result.business_status !== 'CLOSED_PERMANENTLY' &&
        this.isWithinRadius(
          { lat: result.latitude, lng: result.longitude },
          location,
          radius
        )
      );

    } catch (error) {
      if (axios.isAxiosError(error)) {
        const status = error.response?.status;
        const errorMessage = error.response?.data?.errorMessage || error.message;

        if ((status === 429 || status === 500) && retryCount < this.MAX_RETRIES) {
          const delay = this.BASE_DELAY * Math.pow(2, retryCount);
          await new Promise(resolve => setTimeout(resolve, delay));
          return this.searchPlacesWithRetry(queries, location, radius, retryCount + 1);
        }

        throw new Error(`Outscraper API error: ${errorMessage}`);
      }
      throw error;
    }
  }

  private formatCategoryForSearch(category: string): string {
    return category
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  private isWithinRadius(
    point: LatLngLiteral,
    center: LatLngLiteral,
    radiusMiles: number
  ): boolean {
    const distance = this.calculateDistance(center, point);
    const distanceInMiles = distance / 1609.34; // Convert meters to miles
    return distanceInMiles <= radiusMiles;
  }

  private calculateDistance(point1: LatLngLiteral, point2: LatLngLiteral): number {
    const R = 6371e3; // Earth's radius in meters
    const φ1 = point1.lat * Math.PI / 180;
    const φ2 = point2.lat * Math.PI / 180;
    const Δφ = (point2.lat - point1.lat) * Math.PI / 180;
    const Δλ = (point2.lng - point1.lng) * Math.PI / 180;

    const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ/2) * Math.sin(Δλ/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

    return R * c;
  }

  private async getAddressFromCoordinates(location: LatLngLiteral): Promise<string> {
    const geocoder = new google.maps.Geocoder();
    try {
      const result = await geocoder.geocode({
        location: location
      });

      if (result.results[0]) {
        // Try to get city and state first
        const cityComponent = result.results[0].address_components.find(
          component => component.types.includes('locality')
        );
        const stateComponent = result.results[0].address_components.find(
          component => component.types.includes('administrative_area_level_1')
        );

        if (cityComponent && stateComponent) {
          return `${cityComponent.long_name}, ${stateComponent.short_name}`;
        }

        // Fallback to formatted address
        return result.results[0].formatted_address;
      }
      throw new Error('No address found');
    } catch (error) {
      console.error('Geocoding failed:', error);
      // Fallback to coordinates if geocoding fails
      return `${location.lat.toFixed(6)}, ${location.lng.toFixed(6)}`;
    }
  }

  async searchByCategories(
    location: LatLngLiteral,
    radius: number,
    categories: string[]
  ): Promise<PlaceResult[]> {
    const address = await this.getAddressFromCoordinates(location);
    const allResults: OutscraperPlaceResult[] = [];
    const errors: string[] = [];
    let consecutiveErrors = 0;

    // Format search queries with address
    const searchQueries = categories.map(category =>
      `${this.formatCategoryForSearch(category)} near ${address}`
    );

    // Process in batches
    for (let i = 0; i < searchQueries.length; i += this.BATCH_SIZE) {
      try {
        const batchQueries = searchQueries.slice(i, i + this.BATCH_SIZE);
        const results = await this.searchPlacesWithRetry(batchQueries, location, radius);

        if (results?.length > 0) {
          allResults.push(...results);
          consecutiveErrors = 0;
        }
      } catch (error) {
        consecutiveErrors++;
        const message = error instanceof Error ? error.message : 'Unknown error';

        if (message.includes('API key')) {
          throw new Error('Authentication failed. Please check your API key.');
        }

        console.log('message', message);

        errors.push(message);

        if (consecutiveErrors >= 3) {
          throw new Error(`Multiple search attempts failed. Please try again later: ${errors[0]}`);
        }
      }
    }

    if (allResults.length === 0 && errors.length > 0) {
      throw new Error(`No results found. Some searches failed: ${errors[0]}`);
    }

    // Process and return results
    const uniqueResults = Array.from(
      new Map(allResults.map(place => [place.place_id, place])).values()
    );

    return uniqueResults.map(place => ({
      ...place,
      place_id: place.place_id,
      google_id: place.google_id,
      name: place.name,
      formatted_address: place.full_address || place.address || '',
      phone: place.phone || '',
      site: place.site || '',
      geometry: {
        location: {
          lat: place.latitude,
          lng: place.longitude
        }
      },
      rating: place.rating || 0,
      user_ratings_total: place.reviews || 0,
      category: place.category,
      distance: this.calculateDistance(
        location,
        { lat: place.latitude, lng: place.longitude }
      ),
      searchLocation: location,
      searchCategories: categories,
      searchRadius: radius,
      locationLink: place.location_link
    })).sort((a, b) => a.distance - b.distance);
  }
}
